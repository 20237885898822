import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import styled from 'styled-components';

import Header from 'components/Header';

const Wrapper = styled.div`
  background-color: #000026;
  color: #aaaaaa;
  font-family: Helvetica, Arial, sans-serif;
  min-height: 100vh;
  padding: 0;
  margin: 0;
`;


const Main = styled.main`
  padding-left: 2em;
  padding-right: 2em;
  padding-bottom: 2em;
`;

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Legacy of Steel</title>
      </Helmet>
      <Wrapper>
        <Header />
        <Main>{children}</Main>
      </Wrapper>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
