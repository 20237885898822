import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import logoGif from 'assets/images/top-loslogo.gif';
import backgroundGif from 'assets/images/top-back.gif';

const Wrapper = styled.header`
  width: 100%;
  color: white;
  background-color: #000026;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 2.5em;
  line-height: 2.5em;
`;

const Title = styled.div`
  text-align: right;
  font-size: 3.2em;
  font-weight: bold;
  flex: 20%;
  background: url(${backgroundGif});
`;

const MenuContainer = styled.div`
  flex: 80%;
`;
const Menu = styled.ul`
  float: right;
  list-style: none;
  margin: 0;
  margin-right: 2em;
  padding: 0;
`;
const MenuItem = styled.li`
  display: inline-block;
`;

const StyledLink = styled(GatsbyLink)`
  color: #aaaaaa;
  display: block;
  text-decoration: none;
  padding: 0 0.5em;
  overflow: hidden;
`;
const StyledAnchor = styled.a`
  color: #aaaaaa;
  display: block;
  text-decoration: none;
  padding: 0 0.5em;
  overflow: hidden;
`;

const Header = () => {
  return (
    <Wrapper>
      <Title>
        <StyledLink to="/">
          <img src={logoGif} alt="Los Logo"></img>
        </StyledLink>
      </Title>
      <Container>
        <MenuContainer>
          <Menu>
            <MenuItem>
              <StyledLink to="/">Home</StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/archives">Archives</StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/members">Members</StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledAnchor href="http://forums.legacyofsteel.net/phpBB3/index.php">
                Forums
              </StyledAnchor>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/gallery">Gallery</StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/links">Links</StyledLink>
            </MenuItem>
            <MenuItem>
              <StyledLink to="/about/">About</StyledLink>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </Container>
    </Wrapper>
  );
};

export default Header;
